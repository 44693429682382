import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { BreadcrumbQuery } from "../../../graphql-types";
import { usePageContext } from "../context/PageContext";
import * as styles from "./breadcrumb.module.scss";
import { StyledLink } from "../styled/StyledLink";

interface Props {
  label: string;
  labelUrl?: string;
  label2?: string;
  label2Url?: string;
  label3?: string;
  location?: any;
}

const separator = " > ";

export const BreadCrumb: React.FC<Props> = ({
  label,
  labelUrl,
  label2,
  label2Url,
  label3,
  location,
}) => {
  const { lang } = usePageContext();
  const { sanityHomePage } = useStaticQuery<BreadcrumbQuery>(QUERY);
  return (
    <div className={styles.wrapper}>
      <div>
        <StyledLink path="/">
          {sanityHomePage?.data?._rawTitleBreadcrumb?.[lang]}
        </StyledLink>
      </div>
      {label && (
        <>
          <div className={styles.separator}>{separator}</div>
          {labelUrl ? (
            <StyledLink path={labelUrl}>{label}</StyledLink>
          ) : (
            <div>{label}</div>
          )}
        </>
      )}
      {label2 && (
        <>
          <div className={styles.separator}>{separator}</div>
          {label2Url ? (
            <StyledLink path={label2Url}>{label2}</StyledLink>
          ) : (
            <div>{label2}</div>
          )}
        </>
      )}
      {label3 && (
        <>
          <div className={styles.separator}>{separator}</div>
          <div>{label3}</div>
        </>
      )}
    </div>
  );
};

const QUERY = graphql`
  query Breadcrumb {
    sanityHomePage {
      data {
        _rawTitleBreadcrumb
      }
    }
  }
`;
