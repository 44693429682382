// extracted by mini-css-extract-plugin
export var beige = "pro-module--beige--46e01";
export var buttonsWrapper = "pro-module--buttonsWrapper--2877b";
export var contactContainer = "pro-module--contactContainer--46b35";
export var espacePresse = "pro-module--espacePresse--6360d";
export var middleSection = "pro-module--middleSection--a23a2";
export var section = "pro-module--section--48a8d";
export var section1Button = "pro-module--section1Button--59a7a";
export var topSection = "pro-module--topSection--df7c1";
export var videoWrapper = "pro-module--videoWrapper--ea2c8";
export var wrapper = "pro-module--wrapper--a4d43";