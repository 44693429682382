import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import * as styles from "./pageImageHeader.module.scss";

interface Props {
  title: string;
  image: any;
  alt?: string;
  big?: boolean;
}

export const PageImageHeader: React.FC<Props> = ({
  image,
  title,
  alt,
  big = false,
}) => {
  return (
    <header className={styles.wrapper}>
      <GatsbyImage
        image={image}
        alt={alt ?? "tours"}
        className={`${styles.image} ${big ? styles.big : ""}`}
        loading="eager"
      />
      <div className={styles.contentWrapper}>
        <h2>{title}</h2>
      </div>
    </header>
  );
};
