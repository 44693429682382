import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import {
  SanityBlock,
  SanityFile,
  SanityMainImage,
  SanityProFile,
  SanityVideo,
} from "../../../graphql-types";
import * as styles from "./proContentBlocks.module.scss";
import { usePageContext } from "../context/PageContext";
import { StyledLink } from "../styled/StyledLink";
import { SanityProContact, SanityProButton } from "../../../graphql-types";
import { LinkButtonColor, StyledButton } from "../styled/StyledButton";
import ReactPlayer from "react-player";

interface Props {
  blocks: SanityBlock;
}

export const ProContentBlocks: React.FC<Props> = ({ blocks }) => {
  const { lang } = usePageContext();

  const serializers = {
    marks: {
      link: (props: any) => (
        <StyledLink
          path={props.mark.href}
          target={props.mark.target}
          color={props.mark?.color?.hex}
        >
          {props.children}
        </StyledLink>
      ),
      color: (props: any) => {
        return (
          <div
            style={{
              color: `rgba(${props?.value?.rgb?.r},${props?.value?.rgb?.g}, ${props?.value?.rgb?.b}, ${props?.value?.rgb?.a})`,
              display: "inline",
            }}
          >
            {props.children}
          </div>
        );
      },
    },
    types: {
      mainImage: (props: any) => {
        const logo: SanityMainImage = props.node;
        return (
          <img
            src={logo?.image?.asset?.url ?? ""}
            alt={logo?._rawAlt?.[lang] ?? "tours"}
            className={styles.image}
          />
        );
      },
      video: (props: any) => {
        const data: SanityVideo = props.node;
        return (
          <div className={styles.videoWrapper}>
            <ReactPlayer url={data.videoUrl ?? ""} width={"100%"} />
          </div>
        );
      },
      proButton: (props: any) => {
        const data: SanityProButton = props.node;
        return (
          <div className={styles.proButton}>
            <StyledButton button={data} color={data.color as LinkButtonColor} />
          </div>
        );
      },
      proContact: (props: any) => {
        const data: SanityProContact = props.node;
        return (
          <div className={styles.contactContainer}>
            {data?.content?.split("\n").map(e => (
              <p>{e}</p>
            ))}
          </div>
        );
      },
      proFile: (props: any) => {
        const data: SanityProFile = props.node;
        return (
          <div className={styles.proButton}>
            <StyledButton
              color={LinkButtonColor.black}
              label={data?.downloadButtonTitle}
              url={data?.downloadFile?.asset?.url ?? ""}
            />
          </div>
        );
      },
    },
  };

  return (
    <div className={`${styles.wrapper}`}>
      {blocks && <BlockContent blocks={blocks} serializers={serializers} />}
    </div>
  );
};
