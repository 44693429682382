import { graphql } from "gatsby";
import React from "react";
import { usePageContext } from "../components/context/PageContext";
import { PageImageHeader } from "../components/layout/PageImageHeader";
import { ProPageQuery } from "../../graphql-types";
import * as styles from "../styles/pages/pro.module.scss";
import { SEO } from "../components/seo/seo";
import { BreadCrumb } from "../components/breadcrumb/BreadCrumb";
import { ProContentBlocks } from "../components/pro/ProContentBlocks";

interface Props {
  data: ProPageQuery;
  location: any;
}

const ProPage: React.FC<Props> = ({ data }) => {
  const { lang } = usePageContext();
  const { sanityProPage } = data;

  return (
    <>
      <SEO rawMeta={sanityProPage?._rawMetadata} />
      <PageImageHeader
        image={sanityProPage?.data?.bgImage?.image?.asset?.gatsbyImageData}
        title={sanityProPage?.data?._rawTitle?.[lang]}
      />
      <BreadCrumb label={sanityProPage?.data?._rawTitleBreadcrumb?.[lang]} />
      {sanityProPage?.data?.contentList?.map(section => (
        <div
          className={`${styles.section} ${
            section?.backgroundColor === "cream" && styles.beige
          }`}
        >
          <ProContentBlocks blocks={section?._rawContent?.[lang]} />
        </div>
      ))}
    </>
  );
};

export default ProPage;

export const QUERY = graphql`
  query ProPage {
    sanityProPage {
      _rawMetadata(resolveReferences: { maxDepth: 10 })
      data {
        _rawTitleBreadcrumb
        bgImage {
          _rawAlt
          image {
            asset {
              gatsbyImageData
            }
          }
        }
        _rawTitle
        contentList {
          backgroundColor
          content {
            _key
            _type
            _rawFr
            _rawEn
          }
          _rawContent(resolveReferences: { maxDepth: 20 })
        }
      }
    }
  }
`;
